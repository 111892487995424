import { useEffect } from 'react';

import { hasCookie, setCookie } from '~/utils/cookies';

const useCollectAnalyticsCookies = () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = Object.fromEntries(new URLSearchParams(queryString));
    const utmParams: [string, string][] = [];

    Object.entries(urlParams).map((param) => param[0].includes('utm') && utmParams.push(param));

    // eslint-disable-next-line array-callback-return
    utmParams.forEach((param) => {
      if (document.cookie.indexOf(`${param[0]}=`) === -1) {
        document.cookie = `${param[0]}=${param[1]}; domain=bugbug.io`;
      }
    });

    const isDirectVisit = !window.location.search && !document.referrer;
    const visitData = JSON.stringify({
      date: new Date().toISOString(),
      referrer: isDirectVisit ? 'direct' : document.referrer,
      search: isDirectVisit ? 'direct' : window.location.search,
      on: `${window.location.origin}${window.location.pathname}`,
    });

    if (!hasCookie('bb_first_seen')) {
      setCookie({ name: 'bb_first_seen', value: visitData });
    }
    setCookie({ name: 'bb_last_seen', value: visitData });
  }, []);
};

export default useCollectAnalyticsCookies;
