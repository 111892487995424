import jsCookie from 'js-cookie';

const cookieApi = jsCookie;

export const MAX_COOKIE_EXPIRE_TIME_IN_DAYS = 400; // Chrome limitation

export const MAX_COOKIE_EXPIRE_TIME_IN_HOURS = MAX_COOKIE_EXPIRE_TIME_IN_DAYS * 24;

export const getCookie = (name: string) => cookieApi.get(name);

export const hasCookie = (name: string) => Boolean(getCookie(name));

export const deleteCookie = (name: string) => {
  cookieApi.remove(name);
};

interface SetCookie {
  name: string;
  value: string;
  domain?: string;
  expiresInHours?: number;
}

export const setCookie = ({
  name,
  value,
  expiresInHours = MAX_COOKIE_EXPIRE_TIME_IN_HOURS,
}: SetCookie) => {
  if (hasCookie(name)) {
    deleteCookie(name);
  }

  const now = new Date();
  const time = now.getTime();
  const expireTime = time + expiresInHours * 1000 * 3600;
  now.setTime(expireTime);
  cookieApi.set(name, value, {
    expires: now,
    path: '/',
    domain: window.location.hostname,
    secure: true,
    sameSite: 'strict',
  });
};
