import * as Sentry from '@sentry/nextjs';
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  render() {
    const { hasError } = this.state;
    // eslint-disable-next-line react/prop-types
    const { children, Fallback } = this.props;

    if (hasError) {
      return Fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
