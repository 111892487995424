import hljs from 'highlight.js';
import MarkdownIt from 'markdown-it';
import markdownAnchor from 'markdown-it-anchor';
import markdownAttrs from 'markdown-it-attrs';
import markdownCenterText from 'markdown-it-center-text';
import emoji from 'markdown-it-emoji';
import markdownTOC from 'markdown-it-table-of-contents';

import { isSameHostname } from './browser';

/**
 * Transport markdown schema to clean HTML string.
 *
 * dangerouslySetInnerHTML
 * https://pl.reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
 *
 * Markdown to HTML convert?!
 * https://strapi.io/blog/v3-alpha-12-graphql-rich-text-editor-redesigned-dashboard
 *
 * @export
 * @param {string} markdownContent
 * @param {boolean} [noWrapper=false]
 * @return {string}
 */
export function createMarkup(
  markdownContent?: string,
  noWrapper = false,
  openAllLinksInNewTab = false,
): { __html: string } {
  // CKEditor uses GH Flavored Markdown and automatically escapes some characters, which would break our custom elements
  const parsedContent = markdownContent
    ?.replaceAll('\\[TABLE\\_OF\\_CONTENTS\\]', '[TABLE_OF_CONTENTS]')
    ?.replace(/\\->(.*?)\\<-/g, '->$1<-')
    ?.replace(/~(.*?)~/g, '~~$1~~');

  if (!parsedContent) {
    return { __html: '' };
  }

  const md: MarkdownIt = new MarkdownIt({
    html: true,
    highlight: (str, lang) => {
      if (lang === 'embedHtml') {
        return `<div class="markdown-highlight markdown-highlight--embed">${str}</div>`;
      }

      if (lang && hljs.getLanguage(lang)) {
        try {
          return `<pre class="markdown-highlight markdown-highlight--${lang}"><code class="hljs">${
            hljs.highlight(str, { language: lang, ignoreIllegals: true }).value
          }</code></pre>`;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error while highlighting code', error);
        }
      }

      return `<pre class="markdown-highlight"><code class="hljs">${md.utils.escapeHtml(
        str,
      )}</code></pre>`;
    },
  });

  md.use(emoji);
  md.use(markdownTOC, {
    containerHeaderHtml: '<p>Table of Contents</p>',
    includeLevel: [2, 3],
    listType: 'ol',
    markerPattern: /^\[TABLE_OF_CONTENTS\]/im,
  });

  md.use(markdownAnchor);
  md.use(markdownCenterText);
  md.use(markdownAttrs, {
    allowedAttributes: ['id', 'class', 'rel', 'target'],
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const linkRender = (tokens, idx, options, env, self) =>
    self.renderToken(tokens, idx, env, options);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    const link = tokens[idx];

    if (!link?.attrs?.[0]?.[1].includes('bugbug.io')) {
      link.attrPush(['rel', 'nofollow']);
    }

    const href = link.attrGet('href');
    if (href && (openAllLinksInNewTab || !isSameHostname(href))) {
      link.attrSet('rel', 'noreferrer');
      link.attrSet('target', '_blank');
    }

    return linkRender(tokens, idx, options, env, self);
  };

  return { __html: noWrapper ? md.renderInline(parsedContent) : md.render(parsedContent) };
}
