import { getParser } from 'bowser';
import isBot from 'isbot';

const BROWSER_SATISFACTION = {
  chrome: '>=73',
  firefox: '>=63',
  ie: '>11',
  safari: '>=12.2',
  edge: '>=79',
};

const browserIsSupported = (useragent: string) => {
  const browser = getParser(useragent);
  const isSupported = browser.satisfies(BROWSER_SATISFACTION);
  if (typeof isSupported === 'undefined') {
    return true;
  }
  if (isSupported) {
    return true;
  }
  return false;
};

export const isSupportedBrowser = (userAgent: string) =>
  isBot(userAgent) || browserIsSupported(userAgent);

export function isSameHostname(url: string) {
  if (!url.includes('http')) return true;

  try {
    const urlHostname = new URL(url).hostname.replace('www.', '');
    const currentHostname = typeof window === 'undefined' ? 'bugbug.io' : window.location.hostname;
    return urlHostname === currentHostname || urlHostname === 'bugbug.io';
  } catch {
    return true;
  }
}
