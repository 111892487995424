import { parseCookies, setCookie } from 'nookies';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { ReactNode } from 'react';

import { usePopups } from '../Popups/Popups.context';

const CookieConsentContext = createContext<{
  handleCookieConsentButton: () => void;
  isVisible: boolean;
} | null>(null);

const COOKIE_CONSENT_NAME = 'cookie_ok';
const COOKIE_TTL = 60 * 60 * 24 * 90; // ~ 3 months.

export const CookieConsentProvider = ({ children }: { children: ReactNode }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { setCookieBarVisible } = usePopups();

  useEffect(() => {
    const cookies = parseCookies();

    // Show cookie info after 5500 milliseconds delay - do not show this for Google Bot.
    const cookiesTimeout = setTimeout(() => {
      if (!cookies[COOKIE_CONSENT_NAME]) {
        setIsVisible(true);
        setCookieBarVisible(true);
      }
    }, 5500);

    return () => {
      clearTimeout(cookiesTimeout);
    };
  }, [setCookieBarVisible]);

  const handleCookieConsentButton = useCallback(() => {
    setCookie(null, COOKIE_CONSENT_NAME, '1', {
      maxAge: COOKIE_TTL,
      path: '/',
      sameSite: 'Strict',
      secure: true,
    });
    setIsVisible(false);
    setCookieBarVisible(false);
  }, [setIsVisible, setCookieBarVisible]);

  const value = useMemo(
    () => ({ handleCookieConsentButton, isVisible }),
    [handleCookieConsentButton, isVisible],
  );

  return <CookieConsentContext.Provider value={value}>{children}</CookieConsentContext.Provider>;
};

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);

  if (!context) {
    throw new Error('useCookieConsent must be used within a CookieConsentProvider');
  }

  return context;
};
