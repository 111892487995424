/* eslint-disable react/prop-types */
import { useIsomorphicLayoutEffect } from 'framer-motion';
import Head from 'next/head';
import Script from 'next/script';
import { useState } from 'react';

import type { AppProps } from 'next/app';

import { CookieConsentProvider } from '~/components/CookieConsent/CookieConsent.context';
import { PopupsProvider } from '~/components/Popups/Popups.context';
import useAutoScrollToHash from '~/hooks/useAutoScrollToHash';
import useCollectAnalyticsCookies from '~/hooks/useCollectAnalyticsCookies';
import { useTrackOtherLinks } from '~/lib/mixpanel/mixpanel.hooks';

import ErrorBoundary from '../components/ErrorBoundary';
import MessagePage from '../components/MessagePage/MessagePage';
import Unsupported from '../components/Unsupported/Unsupported';
import { isSupportedBrowser } from '../utils/browser';

import 'highlight.js/styles/base16/material-palenight.css';

// https://nextjs.org/docs/basic-features/supported-browsers-features#custom-polyfills
import '../polyfills';

import '../assets/global/globals.scss';
import '../assets/global/generic.scss';

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || '';

const AppContent = ({ Component, pageProps }: AppProps) => {
  const [showUnsupportedMessage, setShowUnsupportedMessage] = useState(false);

  useIsomorphicLayoutEffect(() => {
    const isSupported = isSupportedBrowser(window.navigator.userAgent);
    setShowUnsupportedMessage(!isSupported);
  }, []);

  useAutoScrollToHash();

  useCollectAnalyticsCookies();

  useTrackOtherLinks();

  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=5"
          name="viewport"
          key="viewport"
        />
        {process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' && (
          <meta name="robots" content="noindex,nofollow" key="robots" />
        )}
      </Head>
      {GTM_ID ? (
        <Script
          id="GTM-integration"
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`}
        />
      ) : null}

      {!showUnsupportedMessage && (
        <PopupsProvider>
          <CookieConsentProvider>
            <Component {...pageProps} />
          </CookieConsentProvider>
        </PopupsProvider>
      )}
      {showUnsupportedMessage && <Unsupported />}
    </>
  );
};

const RootApp = (props: AppProps) => {
  const ErrorFallback = (
    <MessagePage
      title="Unexpected error"
      description={
        <>
          Sorry for the inconvenience.
          <br />
          We are trying our best to resolve the problem.
        </>
      }
    />
  );

  return (
    <ErrorBoundary Fallback={ErrorFallback}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <AppContent {...props} />
    </ErrorBoundary>
  );
};

export default RootApp;
