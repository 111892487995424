import { useIsomorphicLayoutEffect } from 'react-use';

const useAutoScrollToHash = () => {
  useIsomorphicLayoutEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);
};

export default useAutoScrollToHash;
