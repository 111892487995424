import Head from 'next/head';

import MessagePage from '../MessagePage/MessagePage';

const Unsupported = () => (
  <>
    <Head>
      <title key="title">BugBug.io | Unsupported browser</title>
      <meta name="robots" content="noindex,nofollow" key="robots" />
    </Head>
    <MessagePage
      title="Unsupported browser"
      description={
        <>
          You are using an outdated and unsupported. <br /> We suggest updating it to the most
          recent version.
        </>
      }
    />
  </>
);

export default Unsupported;
