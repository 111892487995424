import { isSameHostname } from './browser';

export * from './markdown';

/**
 * Prepare URL for links. Add slash before and after slug.
 *
 * @export
 * @param {string} url
 * @return {string}
 */
export function prepareURL(url: string) {
  try {
    // If proper URL string, return that string
    const urlObject = new URL(url);
    return urlObject.href;
  } catch (error) {
    // If string is part of URL, add slash before and after slug.
    return url.replace(/\/?$/, '/').replace(/\/?/, '/');
  }
}

/**
 * Add domain to asset URI depending on environment.
 *
 * @export
 * @param {string} assetURL
 * @return {string}
 */
export function getUploadAssetsURL(assetURL: string) {
  if (assetURL.includes('amazonaws.com')) return assetURL;
  const ENV = process.env.NODE_ENV;
  if (ENV !== 'development' || assetURL.includes('amazonaws.com')) return assetURL;
  const IMAGES_DOMAIN = process.env.NEXT_PUBLIC_IMAGES_DOMAIN_URL;
  return `${IMAGES_DOMAIN}${assetURL}`;
}

/**
 * Detect if the resolution is mobile.
 *
 * @export
 * @return {boolean}
 */
export function isMobile() {
  return window.matchMedia('only screen and (max-width: 991px)').matches;
}

export function getUrlProps(options: { openInNewTab?: boolean; url?: string }) {
  return options.openInNewTab || !isSameHostname(options.url ?? '/')
    ? { target: '_blank', rel: 'noreferrer' }
    : {};
}

export function isUnknownMouseClick(e: Pick<MouseEvent, 'type' | 'button'>) {
  return e.type === 'auxclick' && e.button !== 1;
}
