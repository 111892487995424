import { usePathname, useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { ReactNode } from 'react';

const PopupsContext = createContext<{
  open: (id: string) => void;
  close: (id: string) => void;
  closeAll: () => void;
  isOpen: (id: string) => boolean;
  setCookieBarVisible(value: boolean): void;
  hiddenPopups: string[];
  setHiddenPopup(value: string): void;
  cookieBarVisible: boolean;
} | null>(null);

export const PopupsProvider = ({ children }: { children: ReactNode }) => {
  const [activeIds, setActiveIds] = useState<string[]>([]);
  const [cookieBarVisible, setCookieBarVisible] = useState(false);
  const [hiddenPopups, setHiddenPopups] = useState<string[]>([]);

  const searchParams = useSearchParams();
  const { push } = useRouter();
  const pathname = usePathname();

  const setHiddenPopup = useCallback((id: string) => {
    setHiddenPopups((s) => [...s, id]);
  }, []);

  const clearUrl = useCallback(() => {
    const params = new URLSearchParams(searchParams);
    params.delete('popup');
    push(`${pathname}${params.size > 0 ? `?${params.toString()}` : ''}`, undefined, {
      shallow: true,
    });
  }, [pathname, push, searchParams]);

  const open = useCallback((id: string) => {
    setActiveIds((prevState) => [...prevState, id]);
  }, []);

  const close = useCallback(
    (id: string) => {
      clearUrl();
      setActiveIds((prevState) => prevState.filter((popupId) => popupId !== id));
    },
    [clearUrl],
  );

  const closeAll = useCallback(() => {
    clearUrl();
    setActiveIds([]);
  }, [clearUrl]);

  const isOpen = useCallback((id: string) => activeIds.includes(id), [activeIds]);

  const value = useMemo(
    () => ({
      open,
      close,
      hiddenPopups,
      setHiddenPopup,
      closeAll,
      isOpen,
      setCookieBarVisible,
      cookieBarVisible,
    }),
    [close, hiddenPopups, setHiddenPopup, closeAll, cookieBarVisible, isOpen, open],
  );

  return <PopupsContext.Provider value={value}>{children}</PopupsContext.Provider>;
};

export const usePopups = () => {
  const context = useContext(PopupsContext);

  if (!context) {
    throw new Error('usePopups must be used within a PopupsProvider');
  }

  return context;
};
