import classes from './MessagePage.module.scss';

// eslint-disable-next-line react/prop-types
const MessagePage = ({ title, description }) => (
  <div className={classes.MessagePage}>
    <div className={classes.MessagePage__title}>{title}</div>
    <div className={classes.MessagePage__description}>{description}</div>
  </div>
);

export default MessagePage;
